import React from 'react';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {IoLocation} from "react-icons/io5";
import {BsTelephoneFill} from "react-icons/bs";
import {GrMail} from "react-icons/gr";
import {BiLogoFacebook, BiLogoLinkedin} from "react-icons/bi";
import {AiFillInstagram, AiOutlineTwitter} from "react-icons/ai";
import {useLocation, Link} from "react-router-dom";


const Footer = () => {

  const location = useLocation();

  return <>
    {
        !location.pathname.includes("admin") &&
        <footer className="footer_section">
          <Container className="pt-3 pb-2">
            <Row>
              <Col>
                <Row>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">A Propos</h4>
                    <p className="fs-6 text-white m-0">
                      <ul>
                        <li>FAQ</li>
                        <Link to={`/cgv`}><li>CGU / CGV</li></Link>
                        <Link to={`/mentions-legales`}><li>Mentions légales</li></Link>
                      </ul>
                    </p>
                  </Col>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">Nos services</h4>
                    <p className="fs-6 text-white m-0">
                      <ul>
                        <li>Depot-vente</li>
                        <li>Import</li>
                        <li>Conseils achat-vente</li>
                      </ul>
                    </p>
                  </Col>
                  <Col>
                    <h4 className="fs-4 text-white fw-700">Nous contacter</h4>
                    <p className="fs-6 text-white m-0">
                      <span>
                        <IoLocation className="header-line-2-icon"/>&nbsp;
                        <a href="https://maps.app.goo.gl/gcEgNVnouB48Tg2i8" target="_blank" className="text-white">Castanet-Tolosan</a>
                      </span>
                          <br/>
                          <span>
                        <BsTelephoneFill size="0.9em" className="header-line-2-icon"/>&nbsp;
                            <a href="tel:+33768488664" target="_blank" className="text-white">+33 7 68 48 86 64</a>
                      </span>
                          <br/>
                          <span>
                        <GrMail className="header-line-2-icon"/>&nbsp;
                            <a href="mailto:contact@lecomptoirauto.fr" target="_blank" className="text-white">contact@lecomptoirauto.fr</a>
                      </span>
                    </p>
                    <div className="social-icon">
                      <ul>
                        <li><a href="https://www.instagram.com/lecomptoirauto31?igsh=cmVyN3JiMzBjbGE3" target="_blank"><AiFillInstagram/></a></li>
                        <li><a href="https://www.facebook.com/" target="_blank"><BiLogoFacebook/></a></li>
                        <li><a href="https://www.linkedin.com/" target="_blank"><BiLogoLinkedin/></a></li>
                      </ul>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-white text-center fs-6 mt-4 mb-1">
                  {new Date().getFullYear()} All Rights Reserved. Developed & Designed by Le Comptoir Auto
                </p>
              </Col>
            </Row>
          </Container>
        </footer>
    }
    </>
};
export default Footer;