import React, {useEffect, useState} from 'react';
import {useParams, useNavigate} from "react-router-dom";
import ImageGallery from "react-image-gallery";

import {Container, Row, Col, ListGroup, Button, Badge} from 'react-bootstrap';

import {TbEngine, TbCalendar, TbDirections, TbManualGearbox} from "react-icons/tb";
import { BsFillFuelPumpFill} from "react-icons/bs";


import {fetchAdById, fetchCustomerFolder, fetchPicturesByAdId, fetchAdOptions} from "../hooks/useFetchData";

import {loadingContent} from "../components/general/general-components";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import "react-image-gallery/styles/css/image-gallery.css";


const CarDetail = () => {

    const {adId} = useParams();
    const navigate = useNavigate();

    const [ad, setAd] = useState(null);
    const [customerFolder, setCustomerFolder] = useState(null);
    const [pictures, setPictures] = useState(null);
    const [picturesGallery, setPicturesGallery] = useState([]);
    const [options, setOptions] = useState(null);

    const optionsCategories = [
        {id:1, name: "Confort et Intérieur"},
        {id:2, name: "Technologie et Infodivertissement"},
        {id:3, name: "Sécurité et Assistance à la Conduite"},
        {id:4, name: "Esthétique et Extérieur"},
        {id:5, name: "Dynamique de conduite"},
        {id:6, name: "Autonomie et Économie d’Énergie"},
        {id:7, name: "Accessoires Pratiques"},
        {id:8, name: "Luxe Supplémentaire"},
    ]

    useEffect(() => {
        const fetchData = async () => {
            const resAd = await fetchAdById(adId);
            setAd(resAd);
    
            const resCustomerFolder = await fetchCustomerFolder(resAd.customer_id);
            setCustomerFolder(resCustomerFolder);
    
            const resPictures = await fetchPicturesByAdId(adId);
            setPictures(resPictures);
    
            const resOptions = await fetchAdOptions(adId);
            setOptions(resOptions);
        }

        fetchData();
    }, []);

    useEffect(() => {
        var test = [];
        pictures && pictures.map(picture => {
            console.log("pictureName = " + picture.picture_name);
            test.push({
                original: process.env.REACT_APP_API_ADDRESS + "/uploads/" + customerFolder.folder + "/DV/" + picture.picture_name,
                thumbnail: process.env.REACT_APP_API_ADDRESS + "/uploads/" + customerFolder.folder + "/DV/thumb/thumb_" + picture.picture_name
            });
        });
        setPicturesGallery(test);
    }, [pictures]);


    return (
        <div id="car-detail" style={{clear: "both"}} className='content-div'>
            <Container className="py-4">
                {
                    ad && pictures && picturesGallery && customerFolder
                        ?
                        <>
                            <Row className="mb-4">
                                <Col xs={12} md={6}>
                                    <ImageGallery 
                                        items={picturesGallery} 
                                        // lazyLoad
                                        showPlayButton={false}
                                        showNav={false}
                                    />
                                </Col>
                                <Col xs={12} md={6}>
                                    <div className='ad-header'>
                                        <div className='ad-title'>{ad.make} - {ad.model}</div>
                                        <div className='ad-price'>{ad.price} €</div>
                                    </div>
                                    <ListGroup className="ad-infos" variant="flush">
                                        <ListGroup.Item action>
                                            <TbEngine size="2em" className="me-3" style={{marginTop: "-8px"}}/>
                                            <span className="fs-5 fw-bold">{ad.din_power}ch / {ad.fiscal_power}CV</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <TbCalendar size="2em" className="me-3" style={{marginTop: "-8px"}}/>
                                            <span className="fs-5 fw-bold">{ad.yr}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <TbManualGearbox size="2em" className="me-3" style={{marginTop: "-8px"}}/>
                                            <span className="fs-5 fw-bold">{ad.transmission}</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <TbDirections size="2em" className="me-3" style={{marginTop: "-10px"}}/>
                                            <span className="fs-5 fw-bold">{ad.mileage}km</span>
                                        </ListGroup.Item>
                                        <ListGroup.Item action>
                                            <BsFillFuelPumpFill size="2em" className="me-3" style={{marginTop: "-10px"}}/>
                                            <span className="fs-5 fw-bold">{ad.fuel}</span>
                                        </ListGroup.Item>
                                    </ListGroup>

                                </Col>
                            </Row>

                            <Row className='ad-description'>
                                <h2>Caractéristiques techniques</h2>

                                <Col md={3}>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Marque</div>
                                        <div className='ad-description-item-value'>{ad.make}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Kilométrage</div>
                                        <div className='ad-description-item-value'>{ad.mileage}km</div>
                                    </div>   
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Boite de vitesse</div>
                                        <div className='ad-description-item-value'>{ad.transmission}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Nombre de portes</div>
                                        <div className='ad-description-item-value'>{ad.doors}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Etat extérieur</div>
                                        <div className='ad-description-item-value'>{ad.exterior}</div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Modèle</div>
                                        <div className='ad-description-item-value'>{ad.model}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Carburant</div>
                                        <div className='ad-description-item-value'>{ad.fuel}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Nombre de cylindres</div>
                                        <div className='ad-description-item-value'>{ad.cylinders}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Nombre de places</div>
                                        <div className='ad-description-item-value'>{ad.seats_nb}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Etat intérieur</div>
                                        <div className='ad-description-item-value'>{ad.interior}</div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Version</div>
                                        <div className='ad-description-item-value'>{ad.version}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Puissance Din</div>
                                        <div className='ad-description-item-value'>{ad.din_power}ch</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Cylindrée</div>
                                        <div className='ad-description-item-value'>{ad.displacement}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Couleur</div>
                                        <div className='ad-description-item-value'>{ad.color}</div>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Année</div>
                                        <div className='ad-description-item-value'>{ad.yr}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Puissance fiscale</div>
                                        <div className='ad-description-item-value'>{ad.fiscal_power}CV</div>
                                    </div>    
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Transmission</div>
                                        <div className='ad-description-item-value'>{ad.drive}</div>
                                    </div>
                                    <div className='ad-description-item'>
                                        <div className='ad-description-item-title'>Critair</div>
                                        <div className='ad-description-item-value'>{ad.critair}</div>
                                    </div>
                                </Col>




                            </Row>
                            <Row className='ad-options'>
                                <div>
                                    <h2>Equipements et options</h2>
                                    {options && optionsCategories.map(cat => (
                                        <div>
                                            <div className='ad-option-title'>
                                                {cat.name}
                                            </div>
                                            <div>
                                                {options.map(option => (
                                                    option.category == cat.id ? <Badge bg="secondary">{option.name}</Badge> : null
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </Row>
                            <Row className='ad-options'>
                                <div>
                                    <h2>Description</h2>

                                    <div className='ad-text display-linebreak'>
                                        {ad.ad_text}
                                        {ad && console.log(JSON.stringify(ad.ad_text))}
                                    </div>

                                </div>
                            </Row>
                            
                            <Row>
                                <div className='ad-contact'>
                                    <Button size="lg" className="primary-bg-color border-0 rounded-1 w-25 fs-4 fw-bold"
                                            type="button"
                                            onClick={() => navigate("/contact")}
                                    >
                                        Contactez nous
                                    </Button>
                                </div>
                            </Row>
                        </>
                        :
                            loadingContent
                }
            </Container>
        </div>
    )
};

export default CarDetail;