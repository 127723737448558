import React from 'react';
import AboutSection from '../../components/about-section';

const About = () => {

    return (
        <div id='about'>
            <AboutSection />
        </div>
    );
};
export default About;