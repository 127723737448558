import React, { useState, useEffect } from 'react'
import {Container, Row, Col, Table, Button} from "react-bootstrap"
import { Link } from 'react-router-dom';


const ManageCustomers = () => {

    const [allCustomers, setAllCustomers] = useState(null);

    useEffect(() => {
        const fetchAllCustomers = async () => {
            try {
                const res = await fetch(process.env.REACT_APP_API_ADDRESS + "/customers");
                const allCustomers = await res.json();

                if (res.ok) {
                    setAllCustomers(allCustomers);
                }
                
            } catch(error){
                console.log("Error Fetching data : ", error);
            }
        }

        fetchAllCustomers();        
    }, [])


    return (
        <div id="manage-customers">
            <Container>
                <h1>Gérer les dossiers clients</h1>

                <Table striped bordered hover>
                <thead>
                    <tr>
                    <th>#</th>
                    <th>Client</th>
                    <th>Email</th>
                    <th>Gérer</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        allCustomers && allCustomers.map((customer) => 
                            <tr>
                                <td>{customer.id}</td>
                                <td>{customer.firstname + " " + customer.name}</td>
                                <td>{customer.email}</td>
                                <td><Link to={"/admin/customer/" + customer.id}>Gérer</Link></td>
                            </tr>
                            
                        )
                    
                    }

                </tbody>
                </Table>

            </Container>
        </div>
    );
};

export default ManageCustomers;