import React from 'react';

import ContactSection from '../../components/contact-section';

const Contact = () => {

    return (
        <div id='contact'>
            <ContactSection />
        </div>
    );
};
export default Contact;