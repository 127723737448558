import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import logoImage from '../assets/images/logo.png';
import Swal from 'sweetalert2';

const displayContact = () => {
  Swal.fire({
    title: "Contactez nous !",
    confirmButtonColor: "#136270",
    html: `
    <ul>
    <li>Par téléphone : <a href="tel:+33768488664" target="_blank" class="">+33 7 68 48 86 64</a></li>
    <li>Par mail : <a href="mailto:contact@lecomptoirauto.fr" target="_blank" class="">contact@lecomptoirauto.fr</a></li>
    </ul>
  `,
  });
}


const AboutSection = () => {
    return (
    <div id="about-section">
      <Row className='banner_img'>
      </Row>
     <Container className='about-container'>

        <Row className="mt-1 mb-2 about-panel">
          <Col sm={{span:12, order:"last"}} lg={{span:4, order:"first"}} xxl={{span:6}}>
            <div className="about_img">
                <img src={logoImage}/>
            </div>
          </Col>
          <Col md={{span:12, order:"first"}} lg={{span:8, order:"last"}} xxl={{span:6}}>
            <div className="mt-2 mb-2">
              {/* <h1 className="fs-2 fw-600">
                  Qui sommes nous <span className="primary-color">?</span>
              </h1> */}
              <h1 className='about-title'>
                L'agence Toulousaine <span className="primary-color">Le Comptoir Auto</span> vous accompagne dans l'achat et la vente de votre véhicule d'occasion.
              </h1>
              <p className="about-text m-0">
                  Notre expertise en dépôt-vente nous permet de vous proposer un service complet et sécurisé pour la vente de votre voiture sans vous priver de votre véhicule. Nous nous occupons de tout, de l'estimation de votre véhicule à la publication de son annonce, en passant par les visites et la négociation avec les potentiels acheteurs.
                  <br></br><br></br>
                  {/* Vous recherchez un véhicule d'occasion particulier ? Notre réseau de partenaires nous permet de vous proposer une large sélection de voitures importées de toute l'Europe. Profitez de nos services sur-mesure pour trouver le véhicule qui correspond parfaitement à vos besoins et à votre budget.
                  <br></br> */}
                  Le Comptoir Auto, c'est la garantie d'une transaction simple, rapide et sécurisée pour votre véhicule d'occasion.

              </p>
              <div className="mt-3 about-contact-button-container">
                  <a href="#" onClick={displayContact} className="readmore-btn fs-5 px-3 py-2">Contactez-nous</a>
              </div>
            </div>
          </Col>
        </Row>
     </Container>
    </div>
    );
    };
    export default AboutSection;