import React, { useEffect, useRef, useState } from 'react'
import {Container, Row, Col, Button, Alert } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { fetchCarMakes } from '../../hooks/useFetchData';


const ManageCars = () => {
    const [validated, setValidated] = useState(false);
    const [carMakes, setCarMakes] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setValidated(true);
        if (!e.currentTarget.checkValidity()) {
            e.stopPropagation();
            return;
        }
        
        // // Prepare form values to be sent via POST request
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify({ 
        //         firstname: e.target.firstname.value,
        //         name: e.target.name.value,
        //         email: e.target.email.value,
        //         phone: e.target.phone.value,
        //         address: e.target.address.value,
        //         postcode: e.target.postcode.value,
        //         city: e.target.city.value
        //     })
        // };

        // // Prepare uploaded files to be sent via POST request
        // const formData = new FormData();
        // uploadFiles.forEach((file, index) => {
        //     formData.append(file.type, file.file);
        // });

        // try {
        //     const response = await fetch('http://localhost:5000/customers', requestOptions);
        //     console.log("response status : " + response.status);
        //     const data = await response.json();
        //     console.info("CUSTOMER CREATED => " + JSON.stringify(data));

        //     const responseUpload = await fetch('http://localhost:5000/files/' + data.id + '/upload', {
        //         method: 'POST',
        //         body: formData
        //     });
        //     if (responseUpload.ok) {
        //         setShowSuccess(true);
        //     }
        // } catch (err) {
        //     console.error("error name" + err.name);
        //     console.error("error message" + err.message);
        //     setShowError(true);
        // }
        
    };

    useEffect(() => {
        const fetchData = async () => {
            const resFetch = await fetchCarMakes();
            setCarMakes(resFetch);
        }
        fetchData();
    }, []);

    return (
    <div id="manage-cars">
     <Container>
     <h1>Gestion de la BDD Voitures</h1>

        <Form autoComplete='off' noValidate validated={validated} className='admin-form' onSubmit={handleSubmit}>
            <h2>Créer une référence de voiture</h2>
            <Row className='mb-3'>
                <Col>
                {/* onChange={(e) => {fetchCarModels(e); fetchFinishes(e); fetchOptions(e)}} */}
                    <Form.Select required aria-label="Marque" name="make" >
                        <option value="">Marque</option>
                        {carMakes ? carMakes.map((make) => <option value={make}>{make}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <FloatingLabel controlId="name" label="Nom">
                        <Form.Control type="text" required placeholder="Nom" />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel controlId="email" label="Email">
                        <Form.Control type="email" required placeholder="Email" />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="phone" label="Téléphone">
                        <Form.Control type="tel" required placeholder="Téléphone" />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mb-3'>
                <FloatingLabel controlId="address" label="Adresse">
                    <Form.Control type="text" required placeholder="Adresse" />
                </FloatingLabel>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel controlId="postcode" label="Code postal">
                        <Form.Control type="number" required placeholder="Code postal" />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="city" label="Ville">
                        <Form.Control type="text" required placeholder="Ville" />
                    </FloatingLabel>
                </Col>
            </Row>

            <div className="admin-form-separator"></div>

            <div className="publish-container">
                <Button className="btn btn-primary btn-large centerButton" type="submit">Créer</Button>
            </div>
        </Form>

     </Container>
    </div>
    );
};

export default ManageCars;