import React from 'react'
import {Container, Row, Col} from "react-bootstrap"


const SearchFile = () => {
    return (
    <div id="search-file">
     <Container>
     <h1>Rechercher un document</h1>

     </Container>
    </div>
    );
};

export default SearchFile;