import React from 'react'
import {Container, Row, Col, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import { RiCheckboxCircleFill, RiAddCircleFill, RiShieldCheckLine, RiShieldCheckFill } from "react-icons/ri";
import depotVente01 from '../assets/images/depot-vente/depot-vente-01.jpg';
import depotVente02 from '../assets/images/depot-vente/depot-vente-02.jpg';
import depotVente03 from '../assets/images/depot-vente/depot-vente-03.jpg';
import depotVente04 from '../assets/images/depot-vente/depot-vente-04.jpg';
import depotVente05 from '../assets/images/depot-vente/depot-vente-05.jpg';

import {AiOutlineSafety} from "react-icons/ai";
import { IoIosPaper, IoIosPricetags } from "react-icons/io";
import { IoStorefrontSharp } from "react-icons/io5";
import { FaSearchDollar } from "react-icons/fa";
import Swal from 'sweetalert2';
import CustomerReview from '../components/customer-reviews';



const displayContact = () => {
  Swal.fire({
    title: "Contactez nous !",
    confirmButtonColor: "#136270",
    html: `
    <ul>
    <li>Par téléphone : <a href="tel:+33768488664" target="_blank" class="">+33 7 68 48 86 64</a></li>
    <li>Par mail : <a href="mailto:contact@lecomptoirauto.fr" target="_blank" class="">contact@lecomptoirauto.fr</a></li>
    </ul>
  `,
  });
}


const DepotVenteSection = () => {
    return (
    <div id="depot-vente-section" >
      <Row className='depot-vente_banner_img'>
      </Row>

      <Container className='about-container'>
        <Row className="mt-1 mb-2 about-panel">
          <Col md={{span:12}}>
            <div className="mt-2 mb-2">
              <h1 className='about-title depot-vente-title'>
                Notre formule <span className="primary-color">Dépôt-Vente</span>
              </h1>

              <Row className='mb-4'>
                <Col xs={6} md={4} className='depot-vente-bulletpoint'>
                  <FaSearchDollar/>
                  <p>Estimation précise du marché</p>
                </Col>
                <Col xs={6} md={4} className='depot-vente-bulletpoint'>
                  <RiCheckboxCircleFill/>
                  <p>Gestion complète de la vente</p>
                </Col>
                <Col xs={6} md={4} className='depot-vente-bulletpoint'>
                  <IoIosPaper/>
                  <p>Gestion administrative</p>
                </Col>
                <Col xs={6} md={4} className='depot-vente-bulletpoint'>
                  <RiShieldCheckFill/>
                  <p>Sécurité de paiement</p>
                </Col>
                <Col xs={6} md={4} className='depot-vente-bulletpoint'>
                  <IoStorefrontSharp/>
                  <p>Annonce pro dans la vitrine</p>
                </Col>
                <Col xs={6} md={4} className='depot-vente-bulletpoint'>
                  <IoIosPricetags/>
                  <p>Promotion publicitaire active</p>
                </Col>
              </Row>

              <div className='button-container'>
                <button className='green-rounded-button' onClick={displayContact}>Nous contacter</button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>

     <Container>
      <Row className="mt-5 mb-5">
          <h1 className="fs-1 text-center">Notre processus de <span className="primary-color">dépôt-vente</span></h1>
      </Row>
      <Row className='how-import-row'>
        <Col md={{span:8, order:"first"}} xs={{span:12, order:"first"}}>
        <Row>
          <div className='how-import-subsection'>
            <h1>01</h1>
            <h2>Vérification et estimation du véhicule</h2>
            <p>
              Nous vous proposons un rendez-vous en agence afin d'étudier ensemble la valeur précise de votre véhicule sur le marché, en prenant en considération son état, ses options et son suivi d'entretien.
            </p>
          </div>
        </Row>
        </Col>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={depotVente01} className="how-import-img" />
          </div>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={depotVente02} className="how-import-img" />
          </div>
        </Col>
        <Col md={{span:8, order:"last"}} xs={{span:12, order:"first"}}>
        <Row>
          <div className='how-import-subsection'>
            <h1>02</h1>
            <h2>Mise en avant de votre véhicule</h2>
            <p>
              Nous mettons en lumière votre véhicule grâce a notre page Pro Leboncoin, incluant 20 photos, une description détaillée, et des remontées automatiques. 
              Les services de l'agence (garanties, financement, livraison, etc.) seront mis à disposition de la vente de votre voiture afin de toucher un plus large panel d'acheteur. 
            </p>
          </div>
        </Row>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={{span:8, order:"first"}} xs={{span:12, order:"first"}}>
        <Row>
          <div className='how-import-subsection'>
            <h1>03</h1>
            <h2>Gestion de la vente</h2>
            <p>
            Nous gérons pour vous la partie prise de contact et demandes d'information, ce qui vous délèste du processus de vente.
            </p>
          </div>
        </Row>
        </Col>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={depotVente03} className="how-import-img" />
          </div>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={depotVente04} className="how-import-img" />
          </div>
        </Col>
        <Col md={{span:8, order:"last"}} xs={{span:12, order:"first"}}>
        <Row>
          <div className='how-import-subsection'>
            <h1>04</h1>
            <h2>Gestion des visites</h2>
            <p>
            Vous gardez le véhicule en votre pocession le temps du processus de vente. Les visites seront faites à l'agence selon vos disponibilités. Nous gerons l'ensemble de la visite.
            </p>
          </div>
        </Row>
        </Col>
      </Row>

      <Row className='how-import-row'>
        <Col md={{span:8, order:"first"}} xs={{span:12, order:"first"}}>
        <Row>
          <div className='how-import-subsection'>
            <h1>05</h1>
            <h2>Paiement sécurisé et gestion de l'administratif</h2>
            <p>
              Votre véhicule est vendu ! Nous gérons les démarches administratives et nous vous garantission un paiement par virement instentané lors de la vente du véhicule.
            </p>
          </div>
        </Row>
        </Col>
        <Col md={4}>
          <div className="how-import-img-container">
            <img src={depotVente05} className="how-import-img" />
          </div>
        </Col>
      </Row>
      
     </Container>

     <CustomerReview/>


    </div>
    );
    };
    export default DepotVenteSection;