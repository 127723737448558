import React, { useEffect, useState } from 'react'
import {Container, Row, Col, Button, Alert, Accordion } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ImageUploading from "react-images-uploading";
import { IoAddCircleOutline, IoTrashSharp } from "react-icons/io5";

import {
    fetchAllCustomers,
    fetchCarMakes,
    fetchFinishes,
    fetchOptions,
    fetchCarModels,
    fetchCarVersions,
    fetchCarYears,
    fetchCarTransmissions,
    fetchCarFuels
} from "../../hooks/useFetchData";


const CreateAd = () => {

    const [customers, setCustomers] = useState(null);
    const [carMakes, setCarMakes] = useState(null);
    const [carFinishes, setCarFinishes] = useState(null);
    const [carOptions, setCarOptions] = useState(null);
    const [carModels, setCarModels] = useState(null);
    const [carVersions, setCarVersions] = useState(null);
    const [carYears, setCarYears] = useState(null);
    const [carFuels, setCarFuels] = useState(null);
    const [carTransmissions, setCarTransmissions] = useState(null);
    const colors = ['Beige', 'Blanc', 'Bleu', 'Gris', 'Jaune', 'Marron', 'Noir', 'Orange', 'Prune', 'Rouge', 'Rose', 'Vert', 'Violet', 'Autre'];
    const seats = [2,3,4,5,6,7,8,9];
    const [checkedOptions, setCheckedOptions] = useState([]);
    const [publishChecked, setPublishedChecked] = useState(true);

    const [adId, setAdId] = useState(null);

    const [validated, setValidated] = useState(false);

    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    // For images uploading
    const [images, setImages] = useState([]);
    const maxNumber = 30;
    const onImageChange = (imageList, addUpdateIndex) => {
      // data for submit
      console.log(imageList, addUpdateIndex);
      setImages(imageList);
    };


    const optionsCategories = [
        {id:1, name: "Confort et Intérieur"},
        {id:2, name: "Technologie et Infodivertissement"},
        {id:3, name: "Sécurité et Assistance à la Conduite"},
        {id:4, name: "Esthétique et Extérieur"},
        {id:5, name: "Dynamique de conduite"},
        {id:6, name: "Autonomie et Économie d’Énergie"},
        {id:7, name: "Accessoires Pratiques"},
        {id:8, name: "Luxe Supplémentaire"},
    ]



    const updateCheckStatus = index => {
        // Get position of the index option in the array
        const item = checkedOptions.indexOf(index);

        // If option already present
        if ( item !== -1) {
            // Remove it from the array
            var newArray = [...checkedOptions];
            newArray.splice(item, 1);
            setCheckedOptions(newArray);
        } else {
            // Else, add it to the array
            setCheckedOptions([...checkedOptions, index]);
        }
    }

    const onCustomerChange = async () => {
        const res = await fetchCarMakes();
        setCarMakes(res);
    }

    // When MAKE is selected, fetch all associated models, options and finishes
    const onMakeChange = async (e) => {
        const carModels = await fetchCarModels(e);
        setCarModels(carModels);

        const carFinishes = await fetchFinishes(e);
        setCarFinishes(carFinishes);

        const carOptions = await fetchOptions(e);
        setCarOptions(carOptions);
    }

    // When MODEL is selected, fetch all associated versions
    const onModelChange = async (e) => {
        const carVersions = await fetchCarVersions(e);
        setCarVersions(carVersions);
    }

    // When VERSION is selected, fetch all associated fuels, years and transmissions
    const onVersionChange = async (e) => {
        const carFuels = await fetchCarFuels(e);
        setCarFuels(carFuels);

        const carYears = await fetchCarYears(e);
        setCarYears(carYears);

        const carTransmissions = await fetchCarTransmissions(e);
        setCarTransmissions(carTransmissions);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();

        setValidated(true);
        if (!e.currentTarget.checkValidity()) {
            e.stopPropagation();
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                customer_id: e.target.customer.value,
                make: e.target.make.value,
                model: e.target.model.value,
                version: e.target.version.value,
                year: e.target.year.value,
                transmission: e.target.transmission.value,
                fuel: e.target.fuel.value,
                mileage: e.target.mileage.value,
                fiscal_power: e.target.fiscal_power.value,
                din_power: e.target.din_power.value,
                color: e.target.color.value,
                seats_nb: e.target.seats.value,
                doors: e.target.doors.value,
                critair: e.target.critair.value,
                price: e.target.price.value,
                interior: e.target.interior.value,
                exterior: e.target.exterior.value,
                finish: e.target.finish.value,
                options: checkedOptions,
                ad_text: e.target.ad_text.value,
                published: e.target.publish.checked
            })
        };

        try {
            const response = await fetch(process.env.REACT_APP_API_ADDRESS + '/ads', requestOptions);
            const data = await response.json();
            setAdId(data.id);
        } catch (error) {
            console.error("Error from the server : " + error);
            setShowError(true);
        }
    }

    useEffect(() => {
        const submitImages = async () => {
            console.log("Images not empty, submitting");
            const formData = new FormData();
            images.forEach((image, index) => {
                formData.append(`image`, image.file);
            });

            try {
                const responsePictures = await fetch(process.env.REACT_APP_API_ADDRESS + '/pictures/' + adId + "/upload", {
                    method: 'POST',
                    body: formData
                });
                const dataPictures = await responsePictures.json();
                setShowSuccess(true);
            } catch (error) {
                console.error("Error from the server : " + error);
                setShowError(true);
            }
        }

        if ((images.length !== 0) && (adId)){
            submitImages();
        }
    }, [adId]);

    useEffect(() => {

        const fetchCustomers = async () => {
            const customers = await fetchAllCustomers();
            setCustomers(customers);
        }

        fetchCustomers();       
    }, [])

    return (
    <div id="create-ad">
     <Container>
        <h1>Créer une annonce</h1>

        <Alert variant='danger' show={showError} onClose={() => setShowError(false)} dismissible>
            Erreur serveur
        </Alert>

        <Alert variant='success' show={showSuccess} onClose={() => setShowSuccess(false)} dismissible>
            Annonce créé avec succés !
        </Alert>

        <Form autoComplete='off' noValidate validated={validated} className='admin-form' onSubmit={handleSubmit}>

            <h2>Info client</h2>
            <Row className='mb-3'>
                <Col sm={6}>
                    <Form.Select required aria-label="Client" name="customer" onChange={onCustomerChange}>
                        <option value="">Client</option>
                        {customers ? customers.map((customer) => <option value={customer.id}>{customer.firstname + " " + customer.name + " - " + customer.email}</option>) : null }
                    </Form.Select>
                </Col>
            </Row>

            <div className="admin-form-separator"></div>

            <h2>Infos modèle</h2>
            <Row className='mb-3'>
                <Col>
                    <Form.Select required aria-label="Marque" name="make" onChange={(e) => onMakeChange(e)}>
                        <option value="">Marque</option>
                        {carMakes ? carMakes.map((make) => <option value={make}>{make}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select required aria-label="Modèle" name="model" onChange={e => onModelChange(e)}>
                        <option value="">Modèle</option>
                        {carModels ? carModels.map((model) => <option value={model}>{model}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select required aria-label="Version" name="version" onChange={e => onVersionChange(e)}>
                        <option value="">Version</option>
                        {carVersions ? carVersions.map((version) => <option value={version}>{version}</option>) : null }
                    </Form.Select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Select required aria-label="Carburant" name="fuel">
                        <option value="">Carburant</option>
                        {carFuels ? carFuels.map((fuel) => <option value={fuel}>{fuel}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select required aria-label="Boite de vitesse" name="transmission" >
                        <option value="">Boite de vitesse</option>
                        {carTransmissions ? carTransmissions.map((transmission) => <option value={transmission}>{transmission}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select required aria-label="Année" name="year">
                        <option value="">Année</option>
                        {carYears ? carYears.map((year) => <option value={year}>{year}</option>) : null }
                    </Form.Select>
                </Col>
            </Row>

            <div className="admin-form-separator"></div>

            <h2>Infos véhicule</h2>

            <Row className='mb-3'>
                <Col>
                <InputGroup>
                    <Form.Control
                    placeholder="Kilométrage"
                    aria-label="Kilométrage"
                    name="mileage"
                    type="number"
                    />
                    <InputGroup.Text id="basic-addon2">km</InputGroup.Text>
                </InputGroup>
                </Col>
                <Col>
                <InputGroup>
                    <Form.Control
                    placeholder="Puissance fiscale"
                    aria-label="Puissance fiscale"
                    name="fiscal_power"
                    type="number"
                    />
                    <InputGroup.Text id="basic-addon2">Cv</InputGroup.Text>
                </InputGroup>
                </Col>
                <Col>
                <InputGroup>
                    <Form.Control
                    placeholder="Puissance Dyn"
                    aria-label="Puissance Dyn"
                    name="din_power"
                    type="number"
                    />
                    <InputGroup.Text id="basic-addon2" >Ch</InputGroup.Text>
                </InputGroup>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Select aria-label="Couleur" name="color">
                        <option value=''>Couleur</option>
                        {colors ? colors.map((color) => <option value={color}>{color}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select aria-label="Nombre de places" name="seats">
                        <option value=''>Nombre de places</option>
                        {seats ? seats.map((seat) => <option value={seat}>{seat}</option>) : null }
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select aria-label="Nombre de portes" name="doors">
                        <option value=''>Nombre de portes</option>
                        <option value="3">3</option>
                        <option value="5">5</option>
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select aria-label="Critair" name="critair">
                        <option value=''>Critair</option>
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </Form.Select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <Form.Select aria-label="Etat intérieur" name="interior">
                        <option value=''>Etat intérieur</option>
                        <option value='EXCELLENT'>Excellent</option>
                        <option value='TRES BON'>Très bon</option>
                        <option value='BON'>Bon</option>
                        <option value='CORRECT'>Correct</option>
                    </Form.Select>
                </Col>
                <Col>
                    <Form.Select aria-label="Etat extérieur" name="exterior">
                        <option value=''>Etat extérieur</option>
                        <option value='EXCELLENT'>Excellent</option>
                        <option value='TRES BON'>Très bon</option>
                        <option value='BON'>Bon</option>
                        <option value='CORRECT'>Correct</option>
                    </Form.Select>
                </Col>
            </Row>

            <div className="admin-form-separator"></div>

            <h2>Finition & Options</h2>

            <Row className='mb-3'>
                <Col sm={6}>
                    <Form.Select aria-label="Finition" name="finish">
                        <option value="1">Finition</option>
                        {carFinishes ? carFinishes.map((finish) => <option value={finish.id}>{finish.name}</option>) : null }
                    </Form.Select>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col sm={6}>
                    <Accordion className='options-accordion'>
                    {optionsCategories.map((optionCategory) => (
                        <Accordion.Item eventKey={optionCategory.id}>
                            <Accordion.Header>{optionCategory.name}</Accordion.Header>
                            <Accordion.Body>
                                <ul>
                                    { carOptions && carOptions.map((option) => (
                                        option.category === optionCategory.id &&
                                            <li>
                                                <Form.Check // prettier-ignore
                                                    type='checkbox'
                                                    onChange={() => updateCheckStatus(option.id)}
                                                    name={option.id}
                                                    label={option.name}
                                                />
                                            </li>
                                        ))}
                                </ul>
                            </Accordion.Body>
                        </Accordion.Item>
                    ))}
                    </Accordion>
                </Col>
            </Row>

            <div className="admin-form-separator"></div>

            <h2>Prix</h2>

            <Row className='mb-3'>
                <Col sm={3}>
                    <InputGroup>
                        <Form.Control
                        placeholder="Prix"
                        aria-label="Prix"
                        name="price"
                        type="number"
                        />
                        <InputGroup.Text id="basic-addon2" >€</InputGroup.Text>
                    </InputGroup>
                </Col>
            </Row>
            
            <h2>Texte de l'annonce</h2>

            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                <Form.Control as="textarea" name="ad_text" rows={3} />
            </Form.Group>

            <div className="admin-form-separator"></div>
            
            <h2>Photos</h2>

            <ImageUploading
                multiple
                value={images}
                onChange={onImageChange}
                maxNumber={maxNumber}
                dataURLKey="data_url"
                acceptType={["jpg"]}
            >
                {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    dragProps
                }) => (
                // write your building UI
                <div className="upload__image-wrapper">
                    <button
                    className='upload-button'
                    onClick={(e) => {e.preventDefault(); onImageUpload()}}
                    {...dragProps}
                    >
                    <IoAddCircleOutline/>
                    </button>
                    &nbsp;
                    {imageList.map((image, index) => (
                    <div key={index} className="image-item" onMouseEnter={() => console.log("MOUSE Enter")}>
                        <img src={image.data_url} alt="" width="150" />
                        <div className="image-item__btn-wrapper">
                        <button onClick={(e) => {e.preventDefault(); onImageUpdate(index)}}>Update</button>
                        <button onClick={(e) => {e.preventDefault(); onImageRemove(index)}}>Remove</button>
                        </div>
                    </div>
                    ))}

                    {imageList.length !== 0 && <Button className="delete-button" variant="danger" onClick={(e) => {e.preventDefault(); onImageRemoveAll()}}><IoTrashSharp /></Button>}
                </div>
                )}
            </ImageUploading>

            <div className="admin-form-separator"></div>

            <div className="publish-container">
                <Form.Check // prettier-ignore
                    type='checkbox'
                    // defaultChecked="false"
                    // onChange={setPublishedChecked(!publishChecked)}
                    name="publish"
                    label={'Publier'}
                />

                <Button className="btn btn-primary btn-large centerButton" type="submit">Créer</Button>
            </div>

        </Form>
     </Container>
    </div>
    );
};

export default CreateAd;