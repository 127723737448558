import React from 'react';
import DepotVenteSection from '../../components/depot-vente-section';

const DepotVente = () => {

    return (
        <div id='depot-vente'>
            <DepotVenteSection />
        </div>
    );
};
export default DepotVente;