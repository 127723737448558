import React from 'react';
import CgvSection from '../../components/CGV-section';

const Cgv = () => {

    return (
        <div id='CGV'>
            <CgvSection />
        </div>
    );
};
export default Cgv;