import React, {useRef, useEffect, useState} from 'react';

import {reviewsData} from "../DATA/data.jsx";

import {Button, Card, Carousel, Col, Container, Row} from "react-bootstrap";
import {GrNext, GrPrevious} from "react-icons/gr";
import {AiFillStar} from "react-icons/ai";


const CustomerReview = () => {

    const sliderRef = useRef(null);

    const onPrevClick = () => {
        sliderRef.current.prev();
    };
    const onNextClick = () => {
        sliderRef.current.next();
    };


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [reviewsNumber, setReviewsNumber] = useState(Math.max(1,Math.floor(window.innerWidth/450)));

    const THRESHOLD = 200; // Adjust this value as needed (in pixels)
  
    useEffect(() => {
      const handleResize = () => {
        const newWidth = window.innerWidth;
        const previousWidth = windowWidth;
  
        // Check if the change in width exceeds the threshold
        if (Math.abs(newWidth - previousWidth) >= THRESHOLD) {
          setWindowWidth(newWidth);
          setReviewsNumber(Math.max(1,Math.floor(newWidth/450)));
          console.log("Reviews Number = " + reviewsNumber);
        }
      };
  
      window.addEventListener('resize', handleResize);
  
      // Cleanup function to remove event listener
      return () => window.removeEventListener('resize', handleResize);
    }, [windowWidth]); // Dependency on windowWidth to run on change



    const resultsRender = [];
    for (let i = 0; i < reviewsData.length; i += reviewsNumber) {
        resultsRender.push(
            <Carousel.Item key={`review_carousel_${i}`} interval={99999}>
                <Carousel.Caption className="carousel-caption text-dark text-start">
                    <Row>
                    {
                        reviewsData.slice(i, i + reviewsNumber)
                            .map((review, index) => (
                                <Col key={`review_${i+index}`} className="d-flex align-items-stretch">
                                    <Card className="review-card">
                                        <Card.Body>
                                            <Card.Title className="review-header">
                                                <img className="review-image" src={review.customerImageUrl}/>
                                                <div className="review-title">
                                                    <div className="review-title-name">
                                                        {review.customerName}
                                                    </div>
                                                    <div className='review-title-date'>
                                                        {review.reviewDate}
                                                    </div>                                                    
                                                </div>
                                            </Card.Title>

                                            <div className="review-star">
                                                { Array.from({length: review.customerStar}).map((val, inx) => <AiFillStar key={`star_${inx}`} />) }
                                            </div>

                                            <Card.Text className="m-0">{review.customerReview}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))
                    }
                    </Row>
                </Carousel.Caption>
            </Carousel.Item>
        );
    }


    return (
    <div id="customer-reviews">
        <Container className="py-5">
          <Row className="mb-5">
            <Col>
                <h1 className="fs-1 text-center">Ils nous ont fait <span className="primary-color">confiance</span></h1>
            </Col>
          </Row>
          <Row className="mt-5 justify-content-center">
              <Col xs={12}>
                  <Row>
                      <Col xs={2} md={1} className="position-relative">
                          <div className="position-absolute top-50">
                              <Button variant="outline-dark" onClick={onPrevClick} className="primary-bg-color border-0 rounded-0" size="lg"><GrPrevious color="white"/></Button>
                          </div>
                      </Col>
                      <Col xs={8} md={10} className="p-0">
                          <Carousel ref={sliderRef}>
                              {resultsRender}
                          </Carousel>
                      </Col>
                      <Col xs={2} md={1} className="position-relative">
                          <div className="position-absolute top-50">
                              <Button variant="outline-dark" onClick={onNextClick} className="primary-bg-color border-0 rounded-0" size="lg"><GrNext color="white"/></Button>
                          </div>
                      </Col>
                  </Row>
              </Col>
          </Row>
        </Container>
    </div>
    );
    };
    export default CustomerReview;