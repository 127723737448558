import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/style.css';
import './App.scss';

import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import store from "./redux/app/store";

import AuthProvider from "react-auth-kit";
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"
import ProtectedRoutes from './utils/protected-route';

import ScrollToTop from "./config/ScrollToTop";

import Header from "./components/header";
import Footer from './components/footer';

import Home from './pages/home';

import Login from './pages/auth/login';

import About from './pages/about/about';
import Client from './pages/client/client';
import Services from './pages/services/services';
import Vehicles from './pages/vehicles/vehicles';
import Contact from './pages/contact/contact';
import DepotVente from './pages/depot-vente/depot-vente';

import CarDetail from "./pages/car-detail";

import Admin from "./pages/admin/admin";
import Dashboard from './components/admin/dashboard';
import CreateAd from './components/admin/create-ad';
import ManageAds from './components/admin/manage-ads';
import CreateCustomer from './components/admin/create-customer';
import ManageCustomers from './components/admin/manage-customers';
import Customer from './components/admin/customer';
import SearchFile from './components/admin/search-file';
import ManageCars from './components/admin/manage-cars';
import ManageOptions from './components/admin/manage-options';
import ManageFinishes from './components/admin/manage-finishes';


import Cgv from './pages/legal/CGV';
import MentionsLegales from './pages/legal/mentions-legales';

function App() {

    const persistor = persistStore(store);

    return (
        <Provider store={store}>
            
            <PersistGate persistor={persistor}>
                <AuthProvider
                    store={store}
                    authType={"cookie"}
                    authName={"_auth"}
                    cookieDomain={window.location.hostname}
                    cookieSecure={false}
                >
                <Router>
                <ScrollToTop />
                <Header />
                <Routes>
                    <Route element={<ProtectedRoutes/>}>
                        <Route path='/admin' element={<Admin/>}>
                            <Route path="dashboard" element={<Dashboard/>} />
                            <Route path="create-ad" element={<CreateAd/>} />
                            <Route path="manage-ads" element={<ManageAds/>} />
                            <Route path="create-customer" element={<CreateCustomer/>} />
                            <Route path="manage-customers" element={<ManageCustomers/>} />
                            <Route path="customer/:customer_id" element={<Customer/>} />
                            <Route path="search-files" element={<SearchFile/>} />
                            <Route path="manage-cars" element={<ManageCars/>} />
                            <Route path="manage-options" element={<ManageOptions/>} />
                            <Route path="manage-finishes" element={<ManageFinishes/>} />
                        </Route>
                    </Route>

                    <Route path="/" element={<Home />}/>

                    <Route path="/login" element={<Login />} />

                    <Route path="/about" element={<About />} />
                    <Route path="/depot-vente" element={<DepotVente />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/vehicules" element={<Vehicles />} />
                    <Route path="/contact" element={<Contact />} />

                    <Route path="/annonce/:adId" element={<CarDetail />} />

                    <Route path="/cgv" element={<Cgv />} />
                    <Route path="/mentions-legales" element={<MentionsLegales />} />

                </Routes>
                <Footer />
                </Router>
                </AuthProvider>

            </PersistGate>

        </Provider>
    );
}

export default App;
