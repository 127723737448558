import React from 'react'
import {Container, Row, Col} from "react-bootstrap"


const Dashboard = () => {
    return (
    <div id="dashboard">
     <Container>
     <h1>Dashboard</h1>

     </Container>
    </div>
    );
};

export default Dashboard;