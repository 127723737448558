import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from "react-bootstrap";
import {vehiclesData} from "../DATA/data.jsx";
import {Link, useNavigate} from "react-router-dom";


import 'react-lazy-load-image-component/src/effects/blur.css';

const ServicesCard = ({title, img, imgStyle, to}) => {

    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const handleClickButton = (to) => {
        navigate(to);
    }

    return (
        <div className="services-card" style={{clear: "both"}}>
            <h1>{title}</h1>
            {/* <img 
                src={img} 
                className="services-card-image"
                style={imgStyle}
            /> */}
            <button type="button" style={{marginTop: "20px"}} onClick={() => handleClickButton(to)} class="btn btn-primary primary-bg-color border-0 rounded-1 px-4 fw-bold"> Voir </button>
        </div>
    );
};
export default ServicesCard;