import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { isExpired, decodeToken } from "react-jwt";

const ProtectedRoutes = () => {
    const token = Cookies.get('LCA_token');

    const decodedToken = decodeToken(token);
    const expiredToken = isExpired(token);

    console.log("token = " + token);
    console.log("decodedToken = " + JSON.stringify(decodedToken));
    console.log("expiredToken = " + expiredToken);

    return (!decodedToken || expiredToken) ? <Navigate to="/login"/> : <Outlet/>
}

export default ProtectedRoutes