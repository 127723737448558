import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';


import userImage from '../../assets/images/users/maxime.png';

import { IoMenuOutline, IoHomeOutline, IoAddCircleOutline, IoAlbumsOutline, IoPersonAdd, IoNewspaperSharp, IoSearch, IoCarSport, IoOptions, IoBrush } from "react-icons/io5";

const Item = ({ title, to, icon, selected, setSelected }) => {
  return (
    <MenuItem
      active={selected === title}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <h1>{title}</h1>
      <Link to={to} />
    </MenuItem>
  );
};

const AdminSidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const navigate = useNavigate();

  const handleLogout = () => {
    Cookies.remove('LCA_token');
    navigate("/");
  }

  return (
    <div className="sidebar-container"
      sx={{
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <Sidebar collapsed={isCollapsed} width='280px' backgroundColor="#136270">
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <IoMenuOutline /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: '#ffffff',
            }}
          >
            {!isCollapsed && (
              <div className="sidebar-header">
                <h3> ADMIN </h3>
                <Button className="sidebar-collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
                  <IoMenuOutline />
                </Button>
              </div>
            )}
          </MenuItem>

          {!isCollapsed && (
            <div className="sidebar-profile">
              <div display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  className="user-profile"
                  width="100px"
                  height="100px"
                  src={userImage}
                />
              </div>
              <div className="sidebar-name">
                <h2> Maxime </h2>
              </div>
            </div>
          )}

          <div paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="dashboard"
              icon={<IoHomeOutline />}
              selected={selected}
              setSelected={setSelected}
            />

            <h6
              color={'#ffffff'}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Annonces
            </h6>
            <Item
              title="Créer une annonce"
              to="create-ad"
              icon={<IoAddCircleOutline />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Gérer les annonces"
              to="manage-ads"
              icon={<IoAlbumsOutline />}
              selected={selected}
              setSelected={setSelected}
            />

            <h6
              color={'#ffffff'}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Dossiers clients
            </h6>
            <Item
              title="Créer un dossier client"
              to="create-customer"
              icon={<IoPersonAdd />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Gérer les dossiers clients"
              to="manage-customers"
              icon={<IoNewspaperSharp />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Rechercher un document"
              to="search-files"
              icon={<IoSearch />}
              selected={selected}
              setSelected={setSelected}
            />

            <h6
              color={'#ffffff'}
              sx={{ m: "15px 0 5px 20px" }}
            >
              BDD
            </h6>
            <Item
              title="Voitures"
              to="manage-cars"
              icon={<IoCarSport />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Options"
              to="manage-options"
              icon={<IoOptions />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Finitions"
              to="manage-finishes"
              icon={<IoBrush />}
              selected={selected}
              setSelected={setSelected}
            />
          </div>

          {!isCollapsed && (
              <div className="sidebar-logout">
                <div display="flex" justifyContent="center" alignItems="center">
                  <Button onClick={handleLogout} className="disconnect-button">Déconnexion</Button>
                </div>
                <div className="app-version mt-3">v{process.env.REACT_APP_VERSION}</div>
              </div>
          )}

        </Menu>
      </Sidebar>
    </div>
  );
};

export default AdminSidebar;