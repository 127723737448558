import React, {useState} from 'react';

import useAuthentication from "../../hooks/useAuthentication";
import Cookies from 'js-cookie';

import { NavLink, useNavigate } from "react-router-dom"

import {Container, Row, Col, Form, Button, Alert, Spinner} from "react-bootstrap";
import {loadingContent} from "../../components/general/general-components";

const Login = () => {

    const navigate = useNavigate();

    const {isLoading, message, signInCall} = useAuthentication();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const handleLogin = async (e) => {
        e.preventDefault();

        const response = await signInCall({email, password});
        const loginRes = await response.json(); // TODO : FAILS if Backend is down

        console.log(loginRes);
        loginRes && Cookies.set('LCA_token', loginRes.token, { expires: 1/24, secure: true });

        const timeoutId = setTimeout(() => {
            console.log('Delayed message after 2 seconds!');
            navigate("/admin");
          }, 1000);
    }
    
    return (
        <div id="login" className='content-div'>
            <Container className="pt-4 pb-5">
                <Row>
                    <Col>
                        <h1 className="fs-1 text-center text-uppercase">Login</h1>
                        {
                            message !== null &&
                            (message.isError
                            ? <Alert key="danger" variant="danger">{message.content}</Alert>
                            : <Alert key="success" variant="success">{message.content}</Alert>)
                        }
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <Row className="justify-content-center">
                            <Col xs={12} md={8} className={isLoading ? "text-center" : null}>
                                {
                                    isLoading
                                    ?
                                        loadingContent
                                    :
                                        <Form onSubmit={handleLogin}>
                                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                                <Form.Label>Email address</Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                    required={true}
                                                />
                                            </Form.Group>
                                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                                <Form.Label>Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    placeholder="Password"
                                                    value={password}
                                                    onChange={(e)=>setPassword(e.target.value)}
                                                    required={true}
                                                />
                                            </Form.Group>
                                            <Button
                                                variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold"
                                                type="submit"
                                            >
                                                Login
                                            </Button>
                                        </Form>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Login;