
const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ajout de '0' si nécessaire
    const year = date.getFullYear();
  
    const formattedDate = `${day}/${month}/${year}`;
  
    return(formattedDate);
}



export {formatDate}