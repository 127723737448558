import React, { useRef, useState } from 'react'
import {Container, Row, Col, Button, Alert } from "react-bootstrap"
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import FileUploader from './file-uploader';


const CreateCustomer = () => {

    const [validated, setValidated] = useState(false);
    const [uploadFiles, setUploadFiles] = useState([]);
    const [uploadFileType, setUploadFileType] = useState(null);
    const [showError, setShowError] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);

    const inputCniRef = useRef(null);
    const inputCgRef = useRef(null);
    const inputRibRef = useRef(null);
    const inputContratRef = useRef(null);

    const handleCniButtonClick = (e) => {
        e.preventDefault();
        if (!inputCniRef || !inputCniRef.current) return;
        inputCniRef.current.click();
    }

    const handleCgButtonClick = (e) => {
        e.preventDefault();
        if (!inputCgRef || !inputCgRef.current) return;
        inputCgRef.current.click();
    }

    const handleRibButtonClick = (e) => {
        e.preventDefault();
        if (!inputRibRef || !inputRibRef.current) return;
        inputRibRef.current.click();
    }

    const handleContratButtonClick = (e) => {
        e.preventDefault();
        if (!inputContratRef || !inputContratRef.current) return;
        inputContratRef.current.click();
    }

    function handleFileUpload(e) {
        if (!e.target.files) return;
    
        const file = e.target.files[0];
        setUploadFiles(uploadFiles => [...uploadFiles, {type: uploadFileType, file: file}]);
      }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setValidated(true);
        if (!e.currentTarget.checkValidity()) {
            e.stopPropagation();
            return;
        }
        
        // Prepare form values to be sent via POST request
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ 
                firstname: e.target.firstname.value,
                name: e.target.name.value,
                email: e.target.email.value,
                phone: e.target.phone.value,
                address: e.target.address.value,
                postcode: e.target.postcode.value,
                city: e.target.city.value
            })
        };

        // Prepare uploaded files to be sent via POST request
        const formData = new FormData();
        uploadFiles.forEach((file, index) => {
            formData.append(file.type, file.file);
        });

        try {
            const response = await fetch(process.env.REACT_APP_API_ADDRESS + '/customers', requestOptions);
            console.log("response status : " + response.status);
            const data = await response.json();
            console.info("CUSTOMER CREATED => " + JSON.stringify(data));

            const responseUpload = await fetch(process.env.REACT_APP_API_ADDRESS + '/files/' + data.id + '/upload', {
                method: 'POST',
                body: formData
            });
            if (responseUpload.ok) {
                setShowSuccess(true);
            }
        } catch (err) {
            console.error("error name" + err.name);
            console.error("error message" + err.message);
            setShowError(true);
        }
        
    };

    return (
    <div id="create-customer">
     <Container>
        <h1>Créer un dossier client</h1>

        <Alert variant='danger' show={showError} onClose={() => setShowError(false)} dismissible>
            Erreur serveur
        </Alert>

        <Alert variant='success' show={showSuccess} onClose={() => setShowSuccess(false)} dismissible>
            Dossier client créé avec succés !
        </Alert>

        <Form autoComplete='off' noValidate validated={validated} className='admin-form' onSubmit={handleSubmit}>
            <h2>Infos client</h2>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel controlId="firstname" label="Prénom">
                        <Form.Control type="text" required placeholder="Prénom" />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="name" label="Nom">
                        <Form.Control type="text" required placeholder="Nom" />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel controlId="email" label="Email">
                        <Form.Control type="email" required placeholder="Email" />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="phone" label="Téléphone">
                        <Form.Control type="tel" required placeholder="Téléphone" />
                    </FloatingLabel>
                </Col>
            </Row>
            <Row className='mb-3'>
                <FloatingLabel controlId="address" label="Adresse">
                    <Form.Control type="text" required placeholder="Adresse" />
                </FloatingLabel>
            </Row>
            <Row className='mb-3'>
                <Col>
                    <FloatingLabel controlId="postcode" label="Code postal">
                        <Form.Control type="number" required placeholder="Code postal" />
                    </FloatingLabel>
                </Col>
                <Col>
                    <FloatingLabel controlId="city" label="Ville">
                        <Form.Control type="text" required placeholder="Ville" />
                    </FloatingLabel>
                </Col>
            </Row>

            <div className="admin-form-separator"></div>

            <h2>Documents</h2>

            <Row>
                <Col sm={3}>
                    <FileUploader
                        name="Carte d'Identité"
                        type="CNI"
                        fileUploaded={uploadFiles.some((file) => file.type === "CNI")}
                        onClick={(e) => {handleCniButtonClick(e); setUploadFileType("CNI");}}
                    />
                    <Form.Control ref={inputCniRef} type='file' hidden onChange={handleFileUpload} />
                </Col>
                <Col sm={3}>
                    <FileUploader
                        name="Carte Grise"
                        type="CG"
                        fileUploaded={uploadFiles.some((file) => file.type === "CG")}
                        onClick={(e) => {handleCgButtonClick(e); setUploadFileType("CG");}}
                    />
                    <Form.Control ref={inputCgRef} type='file' hidden onChange={handleFileUpload} />
                </Col>
                <Col sm={3}>
                    <FileUploader
                        name="RIB"
                        type="RIB"
                        fileUploaded={uploadFiles.some((file) => file.type === "RIB")}
                        onClick={(e) => {handleRibButtonClick(e); setUploadFileType("RIB");}}
                    />
                    <Form.Control ref={inputRibRef} type='file' hidden onChange={handleFileUpload} />
                </Col>
                <Col sm={3}>
                    <FileUploader
                        name="Contrat"
                        type="CONTRAT"
                        fileUploaded={uploadFiles.some((file) => file.type === "CONTRAT")}
                        onClick={(e) => {handleContratButtonClick(e); setUploadFileType("CONTRAT");}}
                    />
                    <Form.Control ref={inputContratRef} type='file' hidden onChange={handleFileUpload} />
                </Col>


            </Row>


            <div className="admin-form-separator"></div>

            <div className="publish-container">
                <Button className="btn btn-primary btn-large centerButton" type="submit">Créer</Button>
            </div>
        </Form>
     </Container>
    </div>
    );
};

export default CreateCustomer;