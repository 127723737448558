import React from 'react';

import {Container, Row, Col} from "react-bootstrap";

import {AiOutlineSafety} from "react-icons/ai";
import {HiOutlineStatusOnline} from "react-icons/hi";
import {BiSolidOffer} from "react-icons/bi";


const FeaturesSection = () => {
    return (
    <div id="features-section">
        <Container className="py-5">
            <Row>
                <Col>
                  <h1 className="quinary-color fs-1 p-0 mb-4">Pourquoi choisir Le Comptoir Auto</h1>
                </Col>
            </Row>
            <Row className='features-row'>
              <Col className='features-col' sm={12} md={4}>
                <AiOutlineSafety size="3em" color="#f7d700"/>
                <h4 className="quinary-color fs-4 py-1">Confiance</h4>
                <p className="quinary-color fs-5 m-0 pb-2">Votre satisfaction, notre priorité. Transactions sécurisées pour une sérénité totale.</p>
              </Col>
              <Col className='features-col' sm={12} md={4}>
                <HiOutlineStatusOnline size="3em" color="#f7d700"/>
                <h4 className="quinary-color fs-4 py-1">Expertise</h4>
                <p className="quinary-color fs-5 m-0 pb-2">Des professionnels à votre service pour une estimation juste et une vente rapide.</p>
              </Col>
              <Col className='features-col' sm={12} md={4}>
                <BiSolidOffer size="3em" color="#f7d700"/>
                <h4 className="quinary-color fs-4 py-1">Prix</h4>
                <p className="quinary-color fs-5 m-0 pb-2">Les meilleures offres du marché pour votre véhicule d'occasion. </p>
              </Col>
            </Row>
        </Container>
    </div> 
    );
};
export default FeaturesSection;