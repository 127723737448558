import React from 'react';
import FeaturesSection from '../../components/features-section';

const Services = () => {

    return (
        <div id="services">
            <FeaturesSection />
        </div>
    );
};
export default Services;