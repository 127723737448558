import {collection, doc, getDoc, getDocs, query, where} from "firebase/firestore";
import {db} from "../config/firebase";


const fetchBrands = async () => {

    const docRef = doc(db, "vehicle", "brands");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document (vehicle/brands)!");
        return {};
    }
}
const fetchModels = async () => {

    const docRef = doc(db, "vehicle", "models");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document (vehicle/models)!");
        return {};
    }
}
const fetchCars = async () => {

    const docRef = doc(db, "vehicle", "cars");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        return docSnap.data();
    } else {
        console.log("No such document (vehicle/cars)!");
        return {};
    }
}

const fetchLocations = async () => {

    const docRef = doc(db, "vehicle", "locations");
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
        console.log(docSnap.data())
        return docSnap.data();
    } else {
        console.log("No such document (vehicle/locations)!");
        return {};
    }
}

const fetchReservations = async (owner) => {

    let q;
    if(owner)
        q = query(collection(db, "rentals"), where("reservationOwner", "==", owner));
    else
        q = collection(db, "rentals");

    const querySnapshot = await getDocs(q);

    if(querySnapshot.docs.length){

        return querySnapshot.docs.map(doc => {

            let result = doc.data();
            result["documentId"] = doc.id;
            return result;
        });
    }
    else {
        return null;
    }
}

const fetchContactForms = async () => {

    const querySnapshot = await getDocs(collection(db, "forms"));

    if (querySnapshot.docs) {

        const resultData = querySnapshot.docs.map(i => Object.assign({id: i.id}, i.data()));
        return resultData;
    } else {
        console.log("No such document (forms)!");
        return {};
    }
}

// ========================================================================

const fetchData = async (endpoint) => {
    try {
        const res = await fetch(process.env.REACT_APP_API_ADDRESS + endpoint);
        const response = await res.json();

        if (res.ok) {
            return(response);
        }
        
    } catch(error){
        console.error("Error Fetching data : ", error);
    }
}

const fetchAllAds = async () => {
    return fetchData("/ads");
}

const fetchMainPictures = async () => {
    return fetchData("/pictures/main");
}

const fetchAdById = async (id) => {
    return fetchData("/ads/" + id);
}

const fetchPicturesByAdId = async (adId) => {
    return fetchData("/pictures/ad/" + adId);
}

const fetchAllCustomers = async () => {
    return fetchData("/customers");
}

const fetchCustomerFolder = async (customerId) => {
    return fetchData("/files/folder/" + customerId);
}

const fetchAdOptions = async (adId) => {
    return fetchData("/ads/" + adId + "/options");
}

const fetchCarMakes = async () => {
    return fetchData("/cars/makes");
}

const fetchFinishes = async (e) => {
    return fetchData("/finishes/make/" + e.target.value);
}

const fetchOptions = async (e) => {
    return fetchData("/options/make/" + e.target.value);
}

const fetchCarModels = async (e) => {
    return fetchData("/cars/models/" + e.target.value);
}

const fetchCarVersions = async (e) => {
    return fetchData("/cars/versions/" + e.target.value);
}

const fetchCarYears = async (e) => {
    return fetchData("/cars/years/" + e.target.value);
}

const fetchCarTransmissions = async (e) => {
    return fetchData("/cars/transmissions/" + e.target.value);
}

const fetchCarFuels = async (e) => {
    return fetchData("/cars/fuels/" + e.target.value);
}



export {
    fetchBrands, 
    fetchModels, 
    fetchCars, 
    fetchLocations, 
    fetchReservations, 
    fetchContactForms, 
    fetchAllAds, 
    fetchMainPictures, 
    fetchAdById, 
    fetchPicturesByAdId, 
    fetchAllCustomers,
    fetchCustomerFolder, 
    fetchAdOptions,
    fetchCarMakes,
    fetchFinishes,
    fetchOptions,
    fetchCarModels,
    fetchCarYears,
    fetchCarVersions,
    fetchCarTransmissions,
    fetchCarFuels
}