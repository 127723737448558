
const patchData = async (endpoint, id, body) => {

    const requestOptions = {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: body
    };


    try {
        const res = await fetch(process.env.REACT_APP_API_ADDRESS + endpoint + id, requestOptions);
        const response = await res.json();

        if (res.ok) {
            return(response);
        }
        
    } catch(error){
        console.error("Error Patching data : ", error);
    }
}

const patchAd = async (adId, adBody) => {
    return patchData("/ads/", adId, adBody);
}



export {
    patchAd
}