import React, {useEffect} from 'react';

import useAuthentication from "../hooks/useAuthentication";

import {useNavigate, Link, NavLink, useLocation} from "react-router-dom";
import {Container, Row, Nav, Navbar, NavDropdown, Col, Button} from "react-bootstrap";

import Swal from "sweetalert2";

import logoImage from '../assets/images/logo.png';

import {IoLocation, IoLocationOutline} from "react-icons/io5";
import {LiaCarSideSolid, LiaHandsHelpingSolid} from "react-icons/lia";
import {BsTelephoneFill} from "react-icons/bs";
import {GrMail} from "react-icons/gr";
import {useSelector} from "react-redux";
import {FaUser, FaUserPlus} from "react-icons/fa";
import {RxSlash} from "react-icons/rx";

import { isAdmin } from "../config/general";

const Header = () => {

    const location = useLocation();

    const user = useSelector(({UserSlice}) => UserSlice.user);
    const {signOutCall} = useAuthentication();

    const handleLogout = async () => {
        await signOutCall();
    }

    const displayContact = () => {
        Swal.fire({
          title: "Contactez nous !",
          confirmButtonColor: "#136270",
          html: `
          <ul>
          <li>Par téléphone : <a href="tel:+33768488664" target="_blank" class="">+33 7 68 48 86 64</a></li>
          <li>Par mail : <a href="mailto:contact@lecomptoirauto.fr" target="_blank" class="">contact@lecomptoirauto.fr</a></li>
          </ul>
        `,
        });
      }

    return <>
        {
            // console.log(isAdmin(user.role)) &&
            !location.pathname.includes("admin") &&
            <header id="header">
                {
                    isAdmin(user.role) &&
                    <NavLink to="/admin">
                        <Button variant="primary" className="w-100 rounded-0 fw-bold">
                            Click here for Admin Panel!
                        </Button>
                    </NavLink>
                }
                <Navbar collapseOnSelect expand="lg" fixed="top" className="header-line-1 navbar-dark">
                    <Container>
                        <img src={logoImage} className="header-logo" />
                        <Navbar.Brand as={Link} to="/">Le Comptoir Auto</Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={Link} to="/" eventKey="i">Accueil</Nav.Link>
                                <Nav.Link as={Link} to="/vehicules" eventKey="i">Véhicules</Nav.Link>
                                <Nav.Link as={Link} to="/depot-vente" eventKey="i">Dépôt-Vente</Nav.Link>
                            </Nav>
                            <Nav>
                                {
                                    user.email
                                        ?
                                        <Nav.Link as={Link} to="/my-rentals" eventKey="i">My Rentals <LiaCarSideSolid size="1.25em" className="align-text-top"/></Nav.Link>
                                        :
                                        <>
                                            {/* <Nav.Link as={Link} to="/login" className="d-inline px-0-md" eventKey="i">Login <FaUser className="align-text-top"/></Nav.Link> */}
                                            <Nav.Link onClick={displayContact} className="contactButton" eventKey="i">Contact</Nav.Link>
                                            {/* <Nav.Link as={Link} to="/contact" className="contactButton" eventKey="i">Estimer mon véhicule</Nav.Link> */}
                                        </>
                                }
                                {
                                    user.email && <Button variant="danger" className="py-0" onClick={handleLogout} eventKey="i">Log out</Button>
                                }
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        }
    </>
};

export default Header;