import React from 'react'
import {Container, Row, Col} from "react-bootstrap"
import { IoAddCircleOutline, IoCheckmarkCircleOutline } from "react-icons/io5";



const FileUploader = ( props ) => {
    return (
    <div className="file-uploader-container">
        <div className='file-uploader' onClick={props.onClick}>
            {props.fileUploaded ? <IoCheckmarkCircleOutline/> : <IoAddCircleOutline/>}

        </div>
        <h6>{props.name}</h6>
    </div>
    );
};

export default FileUploader;