import React, {useState} from 'react';
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import {APIProvider, Map, Marker} from '@vis.gl/react-google-maps';


const GoogleMap = () => {


    return (
    <div id="google-map-section">
        <Container className="pt-4">
            <Row className="mb-5">
                <Col lg={8}>
                    <APIProvider apiKey={"AIzaSyDwfiE6L5ds17fPFtcww1RRiTM3zRTTEPI"}>
                        <Map
                            className='google-map'
                            defaultCenter={{lat: 43.51359794995262, lng: 1.5182712722907528}}
                            defaultZoom={15}
                            gestureHandling={'greedy'}
                            disableDefaultUI={false}
                        >
                            <Marker position={{lat: 43.51359794995262, lng: 1.5182712722907528}} />
                        </Map>
                    </APIProvider>
                </Col>
                <Col lg={4}>
                    <div className='map-infos'>
                        <div className='map-infos-address'>
                            <h1>Le Comptoir Auto</h1>
                            <h2>11 rue de la technique</h2>
                            <h2>31320 CASTANET-TOLOSAN</h2>
                        </div>

                        <div className='map-infos-horaires'>
                            <h1>Horaires</h1>
                            <span>
                                <h2>Lundi</h2>
                                <h2>10:00 - 20:00</h2>
                            </span>
                            <span>
                                <h2>Mardi</h2>
                                <h2>10:00 - 20:00</h2>
                            </span>
                            <span>
                                <h2>Mercredi</h2>
                                <h2>10:00 - 20:00</h2>
                            </span>
                            <span>
                                <h2>Jeudi</h2>
                                <h2>10:00 - 20:00</h2>
                            </span>
                            <span>
                                <h2>Vendredi</h2>
                                <h2>10:00 - 20:00</h2>
                            </span>
                            <span>
                                <h2>Samedi</h2>
                                <h2>10:00 - 20:00</h2>
                            </span>
                            <span>
                                <h2>Dimanche</h2>
                                <h2>Fermé</h2>
                            </span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
    );
};
export default GoogleMap;