import { useState } from "react";
import { useDispatch } from "react-redux";
import { db, auth } from "../config/firebase";
import { signInWithEmailAndPassword, signOut } from "firebase/auth";
import { clearUserData, setUser } from "../redux/features/UserSlice";

const useAuthentication = () => {

    // const signIn = useSignIn();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState(null);

    const signInCall = async ({email, password}) => {

        setIsLoading (true);
        try {

            const response = await fetch(process.env.REACT_APP_API_ADDRESS + `/users/login`,
                {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password
                    }),
                }
            )

            setMessage({
                content: "You are successfully logged in!",
                isError: false
            });

            return response;
        }
        catch (err) {
            console.log(err);

            setMessage({
                content: "Incorrect mail or password, please try again!",
                isError: true
            });

        } finally {
            setIsLoading (false);
        }
    };

    const signOutCall = async () => {

        setIsLoading (true);

        try {
            await signOut (auth);
            dispatch(clearUserData());

            setMessage({
                content: "You are successfully logged out!",
                isError: false
            });
        }
        catch (err) {
            console.log(err);

            setMessage({
                content: err,
                isError: true
            });
        }
        finally {
            setIsLoading (false);
        }
    };

    return {isLoading, message, signInCall, signOutCall};
}

export default useAuthentication;