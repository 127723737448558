import React from 'react'
import {Container, Row, Col} from "react-bootstrap"


const ManageFinishes = () => {
    return (
    <div id="manage-finishes">
     <Container>
     <h1>Gestion de la BDD Finitions</h1>

     </Container>
    </div>
    );
};

export default ManageFinishes;