
const deleteData = async (endpoint, id) => {

    const requestOptions = {
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' }
    };


    try {
        const res = await fetch(process.env.REACT_APP_API_ADDRESS + endpoint + id, requestOptions);
        const response = await res.json();

        if (res.ok) {
            return(response);
        }
        
    } catch(error){
        console.error("Error Deleting data : ", error);
    }
}

const deleteAd = async (adId, adBody) => {
    return deleteData("/ads/", adId, adBody);
}



export {
    deleteAd
}