import React, {useEffect, useState} from 'react';
import {Col, Container, Row, Button} from "react-bootstrap";
import {Link} from "react-router-dom";

import {loadingContent} from "./general/general-components";
import {fetchAllAds, fetchMainPictures} from "../hooks/useFetchData";


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const CarOffers = () => {

    const [isLoading, setIsLoading] = useState(false);

    const [allAds, setAllAds] = useState(null);
    const [mainPictures, setMainPictures] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            const fetchAdsRes = await fetchAllAds();
            setAllAds(fetchAdsRes);

            const fetchPicturesRes = await fetchMainPictures();
            setMainPictures(fetchPicturesRes);
        }

        fetchData();
    }, []);

    return (
        <div id="car-offers" style={{clear: "both"}}>
            {allAds && <Container className="py-4">
              <Row className="mb-5">
                <Col>
                    <h1 className="fs-1 text-center">Nos véhicules en <span className="primary-color">dépôt-vente</span></h1>
                </Col>
                {allAds && console.log(JSON.stringify(allAds))}
              </Row>
              <Row>
                    {
                        allAds && mainPictures
                        ?
                            allAds.map((ad) => {
                                const mainPicture = mainPictures.find(picture => picture.ad_id == ad.id);
                                return (
                                    mainPicture && (ad.published === 1) &&
                                    <Col xs={6} md={4} className="py-2" key={`offer_${ad.id}`}>
                                        <div className="gallery-box p-2">
                                            <div className="gallery-img">
                                                
                                                <LazyLoadImage
                                                    src={process.env.REACT_APP_API_ADDRESS + "/uploads/" + mainPicture.folder_name + "/DV/" + mainPicture.picture_name}
                                                    className="img-fluid"
                                                    effect="blur"
                                                />

                                                {ad.sold ? <div className="banner-container">
                                                    <div className="banner">Vendue</div>
                                                </div> : <></>}
                                                
                                            
                                            </div>
                                            <div className="gallery-content text-center">
                                                <h3 className="fs-4 mt-2 fw-600 p-0">
                                                    {ad.make} {ad.model}
                                                </h3>
                                                <p className="fs-5 fw-500 m-0 pt-1 pb-3 primary-color">
                                                    {ad.mileage} km  -  {ad.price} €
                                                </p>
                                                <div className="d-grid pb-2">
                                                    <Link to={"/annonce/"+ad.id} >
                                                        <Button variant="primary rent-now-button primary-bg-color border-0 rounded-1 px-4 fw-bold">Voir</Button>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>)
                            })

                        :
                            loadingContent
                    }
                </Row>
            </Container>
            }
        </div>
    );
};
export default CarOffers;