import React from 'react';
import { Outlet, Routes, Route } from "react-router-dom";

import AdminSidebar from '../../components/admin/sidebar';

const Admin = () => {

    return (
        <div id='admin'>
            <AdminSidebar/>

            <div className="admin-container">
                <Outlet />
            </div>
        </div>
    );
};
export default Admin;