import React, { useState, useEffect } from 'react'
import {Container, Row, Col, Alert, Table, Button} from "react-bootstrap"
import { useParams } from 'react-router-dom';

import { IoLocationOutline, IoCallOutline, IoMailOutline } from "react-icons/io5";

import {loadingContent} from "../general/general-components";
import { formatDate } from '../../hooks/useFormat';


const Customer = () => {

    const { customer_id } = useParams();

    const [customer, setCustomer] = useState(null);
    const [customerDocuments, setCustomerDocuments] = useState([]);
    const [customerAds, setCustomerAds] = useState([]);

    const [showError, setShowError] = useState(null);
    const [showSuccess, setShowSuccess] = useState(null);

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const res = await fetch(process.env.REACT_APP_API_ADDRESS + "/customers/" + customer_id);
                const customer = await res.json();

                if (res.ok) {
                    setCustomer(customer);
                }
                
            } catch(error){
                console.log("Error Fetching data : ", error);
                setShowError(true)
            }
        }

        const fetchCustomerDocuments = async () => {
            try {
                const res = await fetch(process.env.REACT_APP_API_ADDRESS + "/files/customer/" + customer_id);
                const customerDocuments = await res.json();

                if (res.ok) {
                    setCustomerDocuments(customerDocuments);
                }
                
            } catch(error){
                console.log("Error Fetching documents : ", error);
                setShowError(true)
            }
        }

        const fetchCustomerAds = async () => {
            try {
                const res = await fetch(process.env.REACT_APP_API_ADDRESS + "/ads/customer/" + customer_id);
                const customerAds = await res.json();

                if (res.ok) {
                    setCustomerAds(customerAds);
                }
                
            } catch(error){
                console.log("Error Fetching documents : ", error);
                setShowError(true)
            }
        }

        fetchCustomerData();
        fetchCustomerDocuments();
        fetchCustomerAds();
    }, [])


    return (
        <div id="manage-customers">
            <Alert variant='danger' show={showError} onClose={() => setShowError(false)} dismissible>
                Erreur serveur
            </Alert>
            {customer ? 
            <Container>
                <h1>Gérer un dossier client</h1>

                <Alert variant='success' show={showSuccess} onClose={() => setShowSuccess(false)} dismissible>
                    Dossier client créé avec succés !
                </Alert>

                <Row>
                    <Col sm={4}>
                        <div className='customer-info'>
                            <h2>{customer.firstname} {customer.name}</h2>
                            <h3>Créé le : {formatDate(customer.created_on)}</h3>
                            <div className='line-separator'></div>

                            <ul>
                                <li>
                                    <IoLocationOutline/>
                                    {customer.address}
                                </li>
                                <li style={{marginLeft:32}}>
                                    {customer.postcode} - {customer.city}
                                </li>
                                <li>
                                    <IoCallOutline/>
                                    {customer.phone}
                                </li>
                                <li>
                                    <IoMailOutline/>
                                    {customer.email}
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col sm={8}>
                        <Row>
                            <div className='customer-documents'>
                                <h2>Documents</h2>

                                {
                                    customerDocuments && customerDocuments.length > 0 ?
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th>Type</th>
                                                <th>Uploadé le </th>
                                                <th>Taille</th>
                                                <th>Télécharger</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    customerDocuments.map((document) => 
                                                        <tr>
                                                            <td>{document.id}</td>
                                                            <td>{document.file_type}</td>
                                                            <td>TBD</td>
                                                            <td>TBD</td>
                                                            <td><Button>Télécharger</Button></td>
                                                        </tr>
                                                        
                                                    )
                                                
                                                }

                                            </tbody>
                                        </Table>
                                    : <div>Pas de documents</div>

                                }


                            </div>

                        </Row>
                        <Row>
                            <div className='customer-ads'>
                                <h2>Annonces</h2>

                                {customerAds && customerAds.length > 0 ?
                                        <Table striped bordered hover>
                                            <thead>
                                                <tr>
                                                <th>#</th>
                                                <th>Créée le</th>
                                                <th>Véhicule</th>
                                                <th>En ligne</th>
                                                <th>Gérer</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {
                                                    customerAds.map((ad) => 
                                                        <tr>
                                                            <td>{ad.id}</td>
                                                            <td>{ad.created_on}</td>
                                                            <td>TBD</td>
                                                            <td>{ad.published}</td>
                                                            <td><Button>Gérer</Button></td>
                                                        </tr>
                                                        
                                                    )
                                                
                                                }

                                            </tbody>
                                        </Table>
                                    : <div>Pas d'annonce</div>
                                }
                            </div>
                        </Row>
                    </Col>
                </Row>




            </Container>
            : loadingContent }
        </div>
    );
};

export default Customer;