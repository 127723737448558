import React, { useEffect, useState } from 'react'
import {Container, Row, Col, Button, Dropdown} from "react-bootstrap"
import Table from 'react-bootstrap/Table';
import { IoEllipsisHorizontal, IoCheckmarkCircle, IoEllipseOutline } from 'react-icons/io5';

import { formatDate } from '../../hooks/useFormat';
import { fetchAllAds, fetchMainPictures } from '../../hooks/useFetchData';
import { patchAd } from '../../hooks/usePatchData';
import { deleteAd } from '../../hooks/useDeleteData';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Swal from 'sweetalert2';


const ManageAds = () => {

    const [allAds, setAllAds] = useState(null);
    const [mainPictures, setMainPictures] = useState(null);
    const [ad, setAd] = useState(null);

    const handlePublish = async (adId, adPublished) => {

        const res = await patchAd(adId, JSON.stringify({published: !adPublished}));
        console.log("PatchAd = " + res);
        
        Swal.fire({
            title: adPublished ? "Désactivé !" : "Publié !",
            icon: "success",
            confirmButtonColor: "#136270",
        });

        const fetchAdsRes = await fetchAllAds();
        setAllAds(fetchAdsRes);
    }

    const handleSold = async (adId, adSold) => {

        const res = await patchAd(adId, JSON.stringify({sold: !adSold}));
        console.log("PatchAd = " + res);
        
        Swal.fire({
            title: adSold ? "Non vendu" : "Vendu !",
            icon: "success",
            confirmButtonColor: "#136270",
        });

        const fetchAdsRes = await fetchAllAds();
        setAllAds(fetchAdsRes);
    }

    const handleDelete = (adId) => {
        Swal.fire({
            title: "Etes-vous sur ?",
            text: "Toute supression est definitive",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#d33",
            cancelButtonColor: "#136270",
            cancelButtonText: "Annuler",
            confirmButtonText: "Supprimer"
        }).then(async (result) => {
            if (result.isConfirmed) {
                const res = await deleteAd(adId);

                Swal.fire({
                title: "Supprimé !",
                text: "L'annonce a bien été supprimée.",
                icon: "success",
                confirmButtonColor: "#136270"
                });

                const fetchAdsRes = await fetchAllAds();
                setAllAds(fetchAdsRes);
            }
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            const fetchAdsRes = await fetchAllAds();
            setAllAds(fetchAdsRes);

            const fetchPicturesRes = await fetchMainPictures();
            setMainPictures(fetchPicturesRes);
        }

        fetchData();      
    }, [])

    return (
    <div id="manage-ads">
     <Container>
     <h1>Gérer les annonces</h1>

     <Table>
      <thead>
        <tr>
          <th>#</th>
          <th>Véhicule</th>
          <th>Prix</th>
          <th>Date de création</th>
          <th>Publiée</th>
          <th>Vendue</th>
          <th>Gérer</th>
        </tr>
      </thead>
      <tbody>

        {
            allAds && mainPictures && allAds.map((ad) => {
                const mainPicture = mainPictures.find(picture => picture.ad_id == ad.id);
                return(
                    <tr>
                        <td>
                            {mainPicture && <LazyLoadImage
                                src={process.env.REACT_APP_API_ADDRESS + "/uploads/" + mainPicture.folder_name + "/DV/thumb/thumb_" + mainPicture.picture_name}
                                className="img-fluid"
                                effect="blur"
                            />}
                        </td>
                        <td>{ad.make} - {ad.version}</td>
                        <td>{ad.price} €</td>
                        <td>{formatDate(ad.created_on)}</td>
                        <td>{ad.published ? "Publié" : "Brouillon"}</td>
                        <td className='svg-in-array'>{ad.sold ? <IoCheckmarkCircle /> : <IoEllipseOutline />}</td>
                        <td>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    <IoEllipsisHorizontal />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item disabled href="#/action-1">Modifier</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handlePublish(ad.id, ad.published)}>{ad.published ? "Désactiver" : "Publier"}</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleSold(ad.id, ad.sold)}>{ad.sold ? "Non vendu" : "Vendu"}</Dropdown.Item>
                                    <Dropdown.Item disabled href="#/action-3">Archiver</Dropdown.Item>
                                    <Dropdown.Item onClick={() => handleDelete(ad.id)}>Supprimer</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </td>
                    </tr>
                )
            })
        }

      </tbody>
    </Table>

     </Container>

     
    </div>
    );
};

export default ManageAds;