import React from 'react';
import MentionsLegalesSection from '../../components/mentions-legales-section';

const MentionsLegales = () => {

    return (
        <div id='mentions-legales'>
            <MentionsLegalesSection />
        </div>
    );
};
export default MentionsLegales;