import React from 'react'
import {Container, Row, Col} from "react-bootstrap"

const CgvSection = () => {
    return (
    <div id="cgv-section" className='content-div'>
     <Container>

      <h1 className='import-title'>Conditions Générales de Prestations</h1>

      <h2>1. Dispositions générales</h2>
      
      <p>
        Les présentes Conditions Générales s’appliquent au contrat conclu entre le Donneur d’ordre (propriétaire vendeur) et la société Le Comptoir Auto dont les coordonnées figurent sur le contrat de mandat intitulé « Fiche descriptive » (L’Intermédiaire), aux termes duquel le Donneur d’ordre confie à l’Intermédiaire la mission de rechercher un acheteur et de faire toutes les demandes afin de faciliter la vente.
      </p>
      <p>
        Le Donneur d’ordre reconnaît avoir reçu toutes les informations précontractuelles et déclare avoir pris connaissance des présentes Conditions Générales et les accepte.
      </p>

      <h2>2. Modalités des prestations</h2>

      <p>
        Le Donneur d’ordre justifie de la propriété du véhicule objet du présent contrat. Le Donneur d’ordre confirme que son assurance couvre les essais véhicules qui pourront être réalisés par les potentiels acheteurs. Le Donneur d’ordre est seul responsable de l’assurance de son véhicule.
      </p>
      <p>
      Le Donneur d’ordre confie à l’Intermédiaire le soin en son nom et pour son compte de : 
      <ul>
        <li>
        Proposer à la vente son véhicule notamment sur le site internet <a href="https://lecomptoirauto.fr">https://lecomptoirauto.fr</a>; et réaliser toute autre démarche pertinente visant à la vente du véhicule;
        </li>
        <li>
        lui présenter tous acquéreurs éventuels;
        </li>
        <li>
        le cas échéant, favoriser la signature d’un compromis de vente avec tout acheteur acceptant les modalités de la vente du véhicule et notamment le prix.
        </li>
      </ul>
      </p>
      
      <p>
      L’Intermédiaire devra :
      <ul>
        <li>
        entreprendre, d'une façon générale, toutes les démarches nécessaires et qu’il jugera nécessaires pour mener à bien la mission qui lui est confiée ce jour à travers notamment la publication d’annonces sur son site internet et sur tout autre site internet de son choix;
        </li>
        <li>
        informer le Donneur d’ordre de tous les éléments nouveaux pouvant modifier les conditions de la vente;
        </li>
        <li>
        rendre compte au Donneur d’ordre de l’accomplissement de sa mission.
        </li>
      </ul>
      </p>
      
      <p>
      L’Intermédiaire aura pleinement exécuté ses obligations dès lors qu’il aura mis en ligne sur le site <a href="https://lecomptoirauto.fr">https://lecomptoirauto.fr</a> une annonce sur le véhicule.
      </p>

      <h2>3. Exclusivité – conservation du véhicule</h2>

      <p>
      En cas d’exclusivité concédée à l’Intermédiaire, le Donneur d’ordre s’interdit de vendre son véhicule lui-même, en direct.
      L’ exclusivité étant concédée à l'intermédiaire, le Donneur d’ordre s’interdit de diffuser ou de faire diffuser l’annonce de son véhicule par un tiers.
      Dans tous les cas, le Donneur d’ordre s’interdit de recourir à tout tiers concurrent de l’Intermédiaire.
      </p>
      <p>
      Le Donneur d’ordre s’interdit également, en toute hypothèse, de proposer son véhicule sur tout site internet à un prix inférieur à celui qu’il aura communiqué à l’Intermédiaire et d’utiliser les photos du véhicule prises par l’Intermédiaire.
      En cas de vente réalisée en direct par le Donneur d’ordre sans l’intervention de l’Intermédiaire, le Donneur d’ordre s’engage à en informer immédiatement l’intermédiaire.
      </p>
      <p>
      Le Donneur d’ordre s’interdit de vendre son véhicule à un acheteur qui lui sera présenté par l’Intermédiaire sans l’intervention de ce dernier.
      Toute vente réalisée en méconnaissance des droits de l’Intermédiaire, notamment à un acheteur présenté par l’Intermédiaire, rendra le Donneur d’ordre redevable de la commission prévue à l’article 8 dans son intégralité ainsi que d’une pénalité d’un montant de 500 €.
      </p>

      <h2>4. Prix de vente du véhicule</h2>

      <p>
      Le Donneur d’ordre détermine librement le prix de vente attendu de son véhicule.
      Le Comptoir Auto peut conseiller le Vendeur sur le prix le plus adapté à son véhicule.
      Le Vendeur peut modifier à la baisse le prix de mise en vente du véhicule pendant la durée du contrat. Il s’engage à en informer l’Intermédiaire sans délai.
      En accord avec le Donneur d’ordre, il pourra être convenu à titre de prix de mise en vente initial une fourchette de prix à l’intérieur de laquelle Le Comptoir Auto pourra librement proposer à la vente le véhicule. L’Intermédiaire ne peut en aucun cas modifier le prix de vente du véhicule sans l’accord du Donneur d’ordre.
      </p>

      <h2>5. Modalités de conclusion de la vente</h2>

      <p>
      Afin de procéder à la réservation d’un véhicule, l’acheteur doit : 
      <ul>
        <li>
        remplir et signer le formulaire “compromis de vente”
        </li>
        <li>
        régler à l’intermédiaire les frais de prestations de la solution clés en main choisie
        </li>
        <li>
        régler la somme complète du véhicule via le prestataire de paiement cashSentinel pour le mandat associé 
        </li>
      </ul>
      </p>
      <p>
      A défaut d’exécution de la vente par le Donneur d’ordre après la signature du compromis de vente, ce dernier devra verser à l’intermédiaire une indemnité égale au montant de la commission ci-dessous dû sur le mandat. Les frais du forfait clés en main choisi seront restitués à l’acheteur ainsi que la somme versée sur l’outil de paiement CashSentinel.
      Le Vendeur s’engage à livrer le véhicule avec un contrôle technique valide de moins de 6 mois (véhicule de plus de 4 ans).
      </p>

      <h2>6. Durée du Contrat – Résiliation</h2>

      <p>
      6.1. Le contrat est conclu, en principe, pour une durée de trois (2) mois ferme. Cependant, le Donneur d’ordre peut à tout moment décider de retirer de la vente son véhicule. Le Donneur d’ordre devra en informer par tout moyen écrit l’Intermédiaire. Ce dernier s’engage alors à supprimer le véhicule de toute base de données sous un délai maximal de 48 heures à compter de la notification écrite de sa décision par le Donneur d’ordre.
      </p>
      <p>
      6.2. A défaut de volonté expresse du Donneur d’ordre exprimée par écrit de prolonger ou renouveler le contrat pour une nouvelle période de deux mois, le contrat cessera et le véhicule sera retiré de la base de données. 
      En cas de prolongation du contrat pour une durée de deux mois ferme, le véhicule sera maintenu, avec frais supplémentaires, pour cette durée, sur le seul site internet de Le Comptoir Auto.
      Si le Donneur d’ordre souhaite bénéficier des publications sur les sites tiers pour une nouvelle période de deux mois, il devra renouveler son contrat en payant les frais de dossiers définis ci-dessous
      </p>
      <p>
      6.3. Le Contrat prend fin de plein droit et de manière immédiate dans les hypothèses suivantes :
      <ul>
        <li>
        Vente du véhicule par le Donneur d’ordre sans l’intervention de l’Intermédiaire,
        </li>
        <li>
        Vente du véhicule par le Donneur d’ordre avec l’intervention de l’Intermédiaire. Dans ce cas, le contrat cesse uniquement lorsque l’Intermédiaire est réglé en intégralité de la commission due.
        </li>
      </ul>
      </p>
      <p>
      Le Comptoir Auto se réserve le droit de retirer de la vente tout véhicule dont la provenance lui semblerait douteuse ou présentant un vice qui aurait été dissimulé à l‘agence Le Comptoir Auto volontairement ou non lors de la conclusion du mandat et qui le rendrait ainsi impropre à la vente dans les conditions de qualité exigées par Le Comptoir Auto. Dans ce cas, le contrat prendra fin. L’Intermédiaire sera déchargé de toute obligation vis-à-vis du Donneur d’ordre. En tout état de cause, le Donneur d’ordre restera redevable des frais de dossier.
      </p>

      <h2>7. Frais de dossier</h2>

      <p>
      Les frais de dossiers s’élèvent à 59 € TTC pour la formule  « La VIP » et 0 € TTC pour la formule « La Classique ». Le détail de ces offres est disponible en agence. Les frais de dossier sont payés par le Donneur d’ordre à l’ouverture du dossier d'enregistrement et ne sont pas récupérables dans la mesure où ils sont immédiatement consommés.
      </p>

      <h2>8. Commissions de l’Intermédiaire</h2>

      <p>
      Le montant de la commission varie en fonction de la formule. La commission s’élève à 1 190 € TTC pour la formule “La VIP” et 1 490 € TTC pour la formule “La Classique”.  La commission est due par le Donneur d’ordre dès lors qu’un compromis de vente du véhicule est conclu par le Donneur d’ordre avec un acheteur présenté par l’Intermédiaire.
La commission est versée par le Donneur d’ordre au moment de la conclusion définitive de la vente.
Si le Donneur d’ordre décide de ne pas exécuter le compromis de vente sans que l’Intermédiaire ne puisse se voir imputer la responsabilité de l’absence d’exécution de la vente ou si le refus d’exécuter le compromis est une décision conjointe du Donneur d’ordre et de l’acheteur, la commission est due par le Donneur d’ordre en son entier, à la date du refus de procéder à la vente et au plus tard 1 mois après la signature du compromis.
      </p>

      <h2>9. Responsabilité du Donneur d’ordre</h2>

      <p>
      Le Comptoir Auto a uniquement un rôle d’intermédiaire commercial visant à faciliter la vente du véhicule.
      Elle n’est en aucun cas le vendeur du véhicule.
      </p>
      <p>
      L’Intermédiaire ne peut en aucun cas être tenu des garanties pesant sur le vendeur et ne saurait voir sa responsabilité engagée en cas de non-conformité, de vices cachés ou de mauvais fonctionnement du véhicule. Le Donneur d’ordre garantit l’Intermédiaire contre toute demande ou réclamation de l’acheteur relative au véhicule. En cas de volonté de l'acheteur de collecter les informations de contact du Donneur d‘ordre, Le Comptoir Auto sera en droit de les partager avec l‘acheteur après livraison du véhicule.
      </p>
      <p>
      Le Donneur d’ordre est tenu de l’ensemble des garanties légales pesant sur tout vendeur. Le Donneur d’ordre s’engage à fournir à l’Intermédiaire des informations complètes et sincères lors de la conclusion du contrat avec l’Intermédiaire et notamment lors de l’établissement de la fiche descriptive du véhicule et de la mise en place du mandat.
      </p>

      <h2>10. Sélection des véhicules</h2>

      <p>
        Le Comptoir Auto se réserve le droit de choisir les véhicules qui lui sont confiés et de refuser notamment tout véhicule dont le mauvais état apparent ou de fonctionnement, la provenance ou l’identité du propriétaire s’avéreraient douteuse.
      </p>

      <h2>11. Responsabilité de l’Intermédiaire</h2>

      <p>
      En cas de responsabilité de l’Intermédiaire liée à l’exécution de sa mission, cette responsabilité vis-à-vis du Donneur d’ordre est limitée au maximum au montant de la prestation payée par le Donneur d’ordre.
      </p>

      <h2>12. Données personnelles</h2>

      <p>
      Dans le cadre de la relation contractuelle, les données nominatives et personnelles qui sont demandées au Donneur d’ordre sont nécessaires au traitement de son dossier et au respect des dispositions légales et réglementaires afin d’assurer, le cas échéant, la défense des droits de Le Comptoir Auto dans le cadre d’une procédure.
      </p>
      <p>
Ces données peuvent être communiquées aux éventuels partenaires du Donneur d’ordre chargés de l'exécution, du traitement, de la gestion et du paiement des commandes.
Le Donneur d’ordre donne son accord sur le fait que Le Comptoir Auto peut stocker, traiter et utiliser les données collectées. En cas de refus de la part du Donneur d'ordre, l'exécution de la mission sera impossible ; en conséquence, le Donneur d’ordre sera invité à ne pas conclure de contrat avec Le Comptoir Auto.
      </p>
<p>
Les données seront conservées par Le Comptoir Auto pour toute la durée de la relation commerciale et aussi longtemps que nécessaire afin de satisfaire aux finalités décrites ci-dessus.
Le Donneur d’ordre dispose, conformément aux réglementations nationales et européennes en vigueur d'un droit d'accès permanent, de rectification, de suppression de ses données personnelles. Il dispose également du droit à la limitation, à l’oubli, au déréférencement, du droit de ne pas faire l’objet d’un traitement automatisé ou encore du droit à la portabilité de ses données personnelles.
Il peut exercer les droits mentionnés ci-dessus en contactant le siège de Le Comptoir Auto par courrier, à l’adresse suivante : 11 Rue de la Technique - ZI de Vic - 31320 CASTANET-TOLOSAN. Conformément à l’article L.223-2 du Code de la consommation, le Donneur d’ordre est informé qu’il a la possibilité, s’il le souhaite, de s’inscrire sur une liste d’opposition au démarchage téléphonique disponible sur le site internet <a href="https://bloctel.gouv.fr">www.bloctel.gouv.fr</a>
      </p>

      <h2>13. Règlements des différents – Droit applicable</h2>

      <p>
      Les présentes Conditions Générales et tout différend lié à la conclusion et l’exécution du contrat conclu entre le Donneur d’ordre et l’Intermédiaire sont régis et soumis au droit français.
En cas de litige, le Donneur d’ordre s’adressera en priorité à l’Intermédiaire pour trouver une solution amiable.
Conformément à l'article L. 612-1 du code de la consommation, en cas de litige, le Donneur d’ordre est informé qu’il peut recourir gratuitement à un service de médiation.
Les présentes conditions générales de dépôt sont soumises au droit français. En cas de litige, seul sera compétent le tribunal d'instance. À défaut de règlement amiable, les Tribunaux compétents sont les Tribunaux français en application des règles du Code de procédure civile.
      </p>

     </Container>
    </div>
    );
    };
    export default CgvSection;